<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <div v-if="!isAddUserToFacilityDialog">
                <div style="display: flex;">

                    <div style="flex 95;width: 100%;">
                        <md-dialog-title class="figtree-medium-white-20px" v-if="singleStore.id && !currentUser.account.is_trainer">
                            Update a Facility
                        </md-dialog-title>
                        <md-dialog-title class="figtree-medium-white-20px" v-else-if="!currentUser.account.is_trainer"> Create a new
                            Facility
                        </md-dialog-title>
                    </div>
                    <div style="flex 5;cursor: pointer;" 
                        @click="closeDialog()">
                        <img style="width:33;height: 23;" src="@/assets/images/x.png" alt="account-image">

                    </div>
                </div>

                <form class="create-form">
                    <div   v-if="!currentUser.account.is_trainer" class="round-avatar-image">
			            <img v-if="singleStore.image_url" :src="singleStore.image_url" alt="account-image">
			            <img v-else src="@/assets/images/storeDefaultImg.png" alt="account-image">
                        <input type="file" @change="showPreview($event)" ref="hiddenInput" class="input-file">
                        <md-button class="upload-btn"
                                   @click.native="$refs.hiddenInput.click()">Upload
                        </md-button>
                        <div class="photo-error" v-if="showPhotoError">You must upload a photo file (png, jpg, jpeg or bmp) to add a logo !</div>
		            </div>
                    <div class="upload-dialog__preview">
                            <img v-if="previewUrl"
                                 :src="previewUrl"
                                 class="preview-image"
                                 alt="Preview Uploaded Image">
                        </div>
                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">{{currentUser.account.is_trainer ? 'Name' : 'Facility Name'}}</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.name" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>
                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">Street</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.street" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>

                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">Suite</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.country" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>

                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">Choose a country</label>
                            <md-select required v-model="singleStore.country" name="country" id="country">
                                <md-option  style="" v-for="(country, index) in countries" :key="index" :value="country.code">
                                    {{ country.name }}
                                </md-option>
                            </md-select>
                        </md-field>
                    </div>

                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">City</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.city" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>

                    <div>
                        <md-field class="input"  v-if="showProvinceField">
                            <label class="label figtree-normal-white-12px">Province</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.state" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                        <md-field class="input" v-if="showProvinceField === false">
                            <label class="label figtree-normal-white-12px">State</label>
                            <md-select     required v-model="singleStore.state" name="state" id="state">
                                <md-option  style=""  v-for="(state, index) in states" :key="index" :value="state.abbreviation">
                                    {{ state.name }}
                                </md-option>
                            </md-select>
                        </md-field>
                    </div>

                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">{{ zipCodePlaceholder }}</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.zip" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>

                    <!--				<div class="md-layout-item md-raised md-xlarge-size-25 md-large-size-25 md-medium-size-100 md-small-size-100 md-xsmall-size-100"-->
                    <!--					 v-if="!currentUser.account.is_trainer">-->
                    <!--					<div class="status-label">Same mailing address:</div>-->
                    <!--					<toggle-button v-model="isMailingTheSame" color="#a156a0" :sync="true" :width=110 :font-size=17 :height=35 :labels="{checked: 'Yes', unchecked: 'No'}"/>-->
                    <!--				</div>-->

                    <!--				<div class="md-layout-item md-raised md-xlarge-size-65 md-large-size-65 md-medium-size-100 md-small-size-100 md-xsmall-size-100"-->
                    <!--					 v-if="!currentUser.account.is_trainer">-->

                    <!--					<AppInputComponent-->
                    <!--							:disabled="isMailingTheSame"-->
                    <!--							class="create-form__field"-->
                    <!--							required-->
                    <!--							placeholder="Mailing address"-->
                    <!--							v-model="mailing_address"-->
                    <!--					/>-->
                    <!--				</div>-->

                    <div>
                        <md-field class="input">
                            <label class="label figtree-normal-white-12px">Phone</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.phone" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>

                    <div>

                      <md-field class="input">
                            <label class="label figtree-normal-white-12px">Email</label>
                            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                                    v-model="singleStore.email" 
                                    required
                                    >
                            </md-input>
                        </md-field>
                    </div>

                    <div
                        class="md-layout-item md-raised md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                        v-if="!currentUser.account.is_trainer"
                    >

                        <AppInputComponent
                            v-if="userRole == 1 && !singleStore.id"
                            class="create-form__field"
                            controlType="select"
                            required
                            placeholder="Choose an account"
                            v-model="$v.singleStore.account_id.$model"
                            :error="$v.singleStore.account_id.$invalid && $v.singleStore.account_id.$dirty"
                            errorText="Account is required"

                        >
                            <option
                                v-for="(account, index) in accounts" :key="index" :value="account.id">
                                {{ account.name }}
                            </option>
                        </AppInputComponent>
                    </div>

                    <div v-if="!currentUser.account.is_trainer">
                    <md-field class="input"  v-if="!singleStore.id">
                            <label class="label figtree-normal-white-12px">Choose a card</label>
                            <md-select required v-model="singleStore.card_id" name="card" id="card">
                                <md-option  style="" v-for="(card, index) in cards" :key="index" :value="card.id">
                                {{ card.brand }} ***{{ card.last4 }}
                                </md-option>
                            </md-select>
                        </md-field>
 
                    </div>

                  
                        <div style=" display: flex;width: 100%;">
                            <div style="flex:50"></div>
                            <div style="flex:50;">
                                <md-dialog-actions class="form-buttons">
                                  <md-button v-if="disableCloseProp" class="dialog__btn dialog__btn--danger"
                                             @click="logOut()">Log Out
                                  </md-button>
                                  <md-button v-else class="dialog__btn dialog__btn--danger" :disabled="disableCloseProp"
                                             @click="closeDialog()">Cancel
                                  </md-button>
                              
                                    <md-button class="md-primary dialog__btn md-raised md-theme-light"
                                               id="edit-btn"
                                               :disabled="$v.singleStore.$invalid"
                                               v-if="singleStore.id"
                                               @click="createNewStore()">Save Changes
                                    </md-button>
                                
                                    <md-button class="md-primary dialog__btn md-raised md-theme-light"
                                               id="create-btn"
                                               :disabled="$v.singleStore.$invalid || loading"
                                               v-else
                                               @click="createNewStore()">CREATE
                                    </md-button>
                                </md-dialog-actions>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="isAddUserToFacilityDialog">
              <div class="md-layout-item md-raised md-xlarge-size-95 md-large-size-95 md-size-100 add-user-title">
                <span>Invite user(s)</span>
              </div>
              <small>Only people without existing accounts can be invited from this screen. If you’d like to invite someone that already has an existing account, please go to the Facility page to complete those invites.</small>
                <form
                    class="md-layout create-form"
                    v-for="(invitetion, index) in $v.inviteForm.$each.$iter" :key="index">
                    <div class="md-layout-item md-raised md-xlarge-size-95 md-large-size-95 md-size-100 add-user-title">
                        <span>User {{ Number(index)+1 }}</span>
                    </div>

                    <div
                        class="md-layout-item md-raised md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                        <AppInputComponent
                            class="auth-form__field"
                            required
                            placeholder="First Name"
                            v-model="$v.inviteForm.$each[index].first_name.$model"
                            errorText="First Name is required"
                        />
                    </div>

                    <div
                        class="md-layout-item md-raised md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                        <AppInputComponent
                            class="auth-form__field"
                            required
                            placeholder="Last Name"
                            v-model="$v.inviteForm.$each[index].last_name.$model"
                            errorText="First Name is required"
                        />
                    </div>

                    <div
                        class="md-layout-item md-raised md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
                        <AppInputComponent
                            class="invite-form__field input-field"
                            required
                            placeholder="Enter Email"
                            v-model="$v.inviteForm.$each[index].email.$model"
                            :error="$v.inviteForm.$each[index].email.$invalid && $v.inviteForm.$each[index].email.$dirty"
                            errorText="Valid email is required"
                        />
                    </div>

                    <div
                        class="md-layout-item md-raised md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100">

                        <AppInputComponent
                            class="invite-form__field select-field"
                            controlType="select"
                            required
                            placeholder="Choose Role"
                            v-model="$v.inviteForm.$each[index].role_id.$model"
                            :error="$v.inviteForm.$each[index].role_id.$invalid && $v.inviteForm.$each[index].role_id.$dirty"
                            errorText="Role is required">

                            <option v-for="(role, index) in roles" :key="index" :value="role.id">{{ role.name }}
                            </option>

                        </AppInputComponent>
                    </div>


                </form>

                <div
                    class="md-layout-item md-raised md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 add_user">
                    <md-button v-if="inviteForm.length > 0" class="md-fab md-primary md-theme-light"
                               @click="removeUserForInvitation()">
                        <md-icon> remove</md-icon>
                    </md-button>

                    <md-button class="md-fab md-primary md-theme-light"
                               @click="addNewUserForInvitation()">
                        <md-icon> add</md-icon>
                    </md-button>
                </div>
              <md-dialog-actions class="form-buttons">
                <md-button class="dialog__btn dialog__btn--danger" @click="closeInviteDialog()">Go to Dashboard</md-button>
                <md-button :disabled="inviteForm.length === 0 || $v.inviteForm.$invalid || buttonClicked"
                           class="md-primary dialog__btn md-raised md-theme-light"
                           @click="inviteNewUserToFacility()">Invite all users</md-button>
              </md-dialog-actions>
            </div>

        </md-dialog>

<!--        <AuthRegistrationCompleteModal :show-complete-prop.sync="showComplete"/>-->
    </div>
</template>

<script>

import {required, email, requiredIf} from 'vuelidate/lib/validators';
import AccountService from '../../services/account.service';
import CardService from '../../services/card.service';
import RoleService from '../../services/role.service';
import {mapGetters} from 'vuex';
import {statesConstData, countries} from '@/constants';
import AuthRegistrationCompleteModal from '@/components/Auth/AuthRegistrationCompleteModal';

export default {
    name: 'CreateStoreModal',
    components: {
        AuthRegistrationCompleteModal,
    },

    props: {
        disableCloseProp: {
            type: Boolean,
            default: false,
        },

        showDialog: {
            type: Boolean,
            default: false,
        },
        storeProp: {
            type: Object,
            default: {
                name: '',
                street: '',
                suite: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                img: '',
                email: '',
                account_id: null,
                card_id: null,
            },
        },
    },
    data: () => ({
        singleStore: {
            name: '',
            street: '',
            suite: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            // mailing_address: '',
            phone: '',
            img: '',
            email: '',
            account_id: null,
            card_id: null,
        },
        inviteForm: [
            {
                first_name: '',
                last_name: '',
                email: '',
                role_id: null,
            },
        ],
        roles: [],
        accounts: [],
        cards: [],
        states: statesConstData,
        countries: countries,
        currentStoreId: null,
        currentAccountId: null,
        // isMailingTheSame: false,
        previewUrl: '',
        showPhotoError: false,
        isAddUserToFacilityDialog: false,
        showComplete: false,
        showProvinceField: false,
        zipCodePlaceholder: 'Zip Code',
        loading: false,
        buttonClicked: false
    }),

    validations: {
        singleStore: {
            name: {required},
            street: {required},
            city: {required},
            // state: {required},
            zip: {required
              },
            phone: {required,
              testPhoneNumber(value) {
                const phoneNumberRegex = /^\+?\d{8,}$/
                return phoneNumberRegex.test(value);
              }
              },
            email: {required, email},
            account_id: {
                required: requiredIf(function() {
                    return !(this.singleStore.hasOwnProperty('id') || this.currentUser.account.is_trainer);
                }),
            },
            card_id: {
                required: requiredIf(function() {
                    return !(this.singleStore.hasOwnProperty('id') || this.currentUser.account.is_trainer);
                }),
            },
        },
        inviteForm: {
            $each: {
                first_name: {required},
                last_name: {required},
                email: {required, email},
                role_id: {required},
            },
        },
    },

    methods: {

        showPreview(event) {

            const input = event.target;


            if (input.files && input.files[0]) {

                let splitFile = input.files[0].name.split('.');
                let splitFileLength = splitFile.length;
                let fileExtension = (input.files[0].name.split('.')[splitFileLength - 1]).toLowerCase();

                if (fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg' ||
                    fileExtension === 'bmp') {

                    this.singleStore.img = input.files[0];
                    const reader = new FileReader();

                    reader.onload = e => {
                        this.previewUrl = e.target.result;
                        input.value = '';
                    };

                    reader.readAsDataURL(input.files[0]);

                    this.showPhotoError = false;

                    console.log(this.showPhotoError);
                } else {
                    this.showPhotoError = true;
                }
            }
        },

        closeDialog() {

            this.$emit('update:showDialog', false);
        },

      logOut() {
        this.$store.dispatch('auth/signOut').then((res) => {

          this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {song: null, name: null});
          this.$router.replace('/login');
        });
      },

        closeInviteDialog() {
            if (this.currentUser.account.has_store === false) {
                this.showComplete = true;
                this.$router.push('/dashboard');
            } else {
                this.$router.push('/dashboard');
            }

            this.$store.dispatch('auth/getMe');
            // this.isAddUserToFacilityDialog = false;
        },

        createNewStore() {
            this.loading = true;
            let _this = this;
            let singleStoreObj = this.singleStore;
            // singleStoreObj.mailing_address = _this.mailing_address;

            let url = '/stores';

            if (singleStoreObj.hasOwnProperty('id')) {

                url = '/stores/' + singleStoreObj.id;
            }

            if(this.cards.length === 0){

                _this.$store.dispatch('showSnackbarMessage', {
                    message: 'You need to create a card first.',
                    duration: 4000,
                    mode: 'success',
                });
              _this.loading = false;
                return;
            }

            if(this.currentUser.account.is_trainer){

                singleStoreObj.card_id = this.cards[0].id;
            }

            let bodyFormData = new FormData();

            bodyFormData.set('name', singleStoreObj.name);
            bodyFormData.set('street', singleStoreObj.street);
            bodyFormData.set('suite', singleStoreObj.suite);
            bodyFormData.set('city', singleStoreObj.city);
            bodyFormData.set('state', singleStoreObj.state);
            bodyFormData.set('zip', singleStoreObj.zip);
            bodyFormData.set('country', singleStoreObj.country);
            // bodyFormData.set('mailing_address', singleStoreObj.mailing_address);
            bodyFormData.set('phone', singleStoreObj.phone);
            bodyFormData.set('image', singleStoreObj.img);
            bodyFormData.set('email', singleStoreObj.email);
            bodyFormData.set('account_id', singleStoreObj.account_id);
            bodyFormData.set('primary_contact_id', this.currentUser.id);
            bodyFormData.set('card_id', singleStoreObj.card_id);

            this.$axios({
                method: 'post',
                url: url,
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data'},
            }).then(function(response) {
                // console.log(response);

                _this.$emit('addNewStore', response.data);
                _this.currentStoreId = response.data.id;
                _this.currentAccountId = Number(response.data.account_id);

                _this.$store.dispatch('showSnackbarMessage', {
                    message: 'Saved',
                    duration: 4000,
                    mode: 'success',
                });
              _this.loading = false;
                // if (_this.currentUser.is_setup == false) {

                // _this.closeDialog();
              if(_this.currentUser.account.is_trainer){
                _this.closeInviteDialog();
              }else {
                _this.isAddUserToFacilityDialog = true;
              }

                // }

            }).catch(function(error) {

                console.log(error);

                _this.$store.dispatch('showSnackbarMessage',
                    {message: 'Something went wrong. Try again.', duration: 4000, mode: 'fail'});
              _this.loading = false;
            });
        },

        getAllAccounts() {

            if (this.userRole == 1) {

                return AccountService.getAccounts().then((res) => {

                    this.accounts = res;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage',
                        {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                });
            } else {

                this.singleStore.account_id = this.$store.getters['auth/getCurrentUser'].account_id;
            }
        },

        getAllRoles() {

            RoleService.getAll().then((res) => {

              this.roles = res.filter(role => role.id > this.userRole && role.id !== 4);
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
            });
        },

        getAllCards() {

            CardService.getAllCards().then((res) => {

                this.cards = res;
            });
        },

        addNewUserForInvitation() {

            let singleInvite = {first_name: '', last_name: '', email: '', role_id: null};
            this.inviteForm.push(singleInvite);
        },

        removeUserForInvitation() {

            this.inviteForm.pop();
        },

        async inviteNewUserToFacility() {
          this.buttonClicked = true;
          let formIndex = 0;
          let invitationSucceeded = 0;
          let invitationFailed = 0;
          while (formIndex < this.inviteForm.length) {
            const inviteUser = this.inviteForm[formIndex];
            const singleInvitation = {
              ...inviteUser,
              store_id: this.currentStoreId,
              account_id: this.currentAccountId,
            };

            await this.$axios.post('/invites', singleInvitation).then(() => {

              invitationSucceeded++;
              this.inviteForm.splice(formIndex, 1);

            }).catch(() => {

              formIndex++;
              invitationFailed++;
            });
          }
          if (invitationFailed === 0) {
            await this.$store.dispatch('showSnackbarMessage',
                {message: 'User(s) were invited.', duration: 3000, mode: 'success'});
          } else {
            if (invitationSucceeded > 0) {
              await this.$store.dispatch('showSnackbarMessage',
                  {message: invitationSucceeded + ' invitation(s) sent.', duration: 2000, mode: 'success'});
            }
            setTimeout( async () => {
              await this.$store.dispatch('showSnackbarMessage',
                  {message: invitationFailed + ' invitation(s) failed.', duration: 2000, mode: 'fail'});
            }, 2500);
          }
          this.buttonClicked = false;
        },

        async fetchData() {

            this.getAllAccounts();
            this.getAllRoles();
            this.getAllCards();
        },

    },

    computed: {
        // mailing_address() {
        //
        //     return this.singleStore.state + ' ' +
        //            this.singleStore.city + ' ' +
        //            this.singleStore.street + ' ' +
        //            this.singleStore.suite + ' ' +
        //            this.singleStore.zip;
        // },

        ...mapGetters(
            {
                userRole: 'auth/currentUserRole',
                currentUser: 'auth/getCurrentUser',
            },
        ),
    },

    watch: {

        'singleStore.country': function(value) {


            if (value != '' && value !== 'US') {

                this.showProvinceField = true;
                this.zipCodePlaceholder = 'Postal Code';

                return;
            }

            this.showProvinceField = false;
            this.zipCodePlaceholder = 'ZIP Code';
        },
    },

    created() {

        let _this = this;

        _this.fetchData().then(() => {

            // console.log(_this.storeProp)
            if (_this.storeProp) {

                _this.singleStore = _this.storeProp;
            }
        });
    },

    updated() {

        if (this.storeProp && this.storeProp.account_id) {

            this.singleStore.account_id = this.storeProp.account_id.toString();
        }
    },
};
</script>

<style lang="scss" scoped>
@include dialogSettings();
.dialog__hint {
    margin-bottom: 16px;
}

.md-dialog {


    .choose-file-btn {
        width: 30%;
    }

    .upload-cont, .add_user {
        text-align: center;
    }

    .file-upload-label {
        font-size: 2rem;
    }

    .add-user-title {
        font-size: 2.2rem;
        font-weight: 600;
        text-align: center;
        padding: 10px;
    }

    .add_user {
        border-radius: 50%;
    }
}

.form-buttons {
    margin-top: 20px;
    justify-content: space-around;
}

.input-file {
    display: none;
}
.round-avatar-image {
    margin: 25px 0 25px 0;
    img {
		border-radius: 50%;
		width: 46px;
		height: 46px;
    }
}
.create-form {
    margin-top: 19px;

    .md-layout-item {
        margin: 0 auto;
    }
}

::v-deep .control-field__select,
::v-deep .control-field__input {
    height: 60px !important;
}

::v-deep .control-field {
    padding-bottom: 19px !important;
}

.md-dialog-title {
    text-align: center;
    font-size: 2.3rem;
}

.upload-dialog__preview {
    /*max-width: 100px;*/
	margin: 20px auto;
}

.photo-error {
    color: #ff0900;
	font-size: 1.7rem;
	margin: 7px auto 0;
}

.state-msg {
    color: red;
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.5rem;
}
.upload-btn{
        width: 92px;
        height: 30px;
        background: rgba(255, 255, 255, 0.12);
        border-radius: 5px;
    }
</style>
